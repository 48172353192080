.wrapper {
  display: inline-block;
  width: var(--spacing-6);
  height: var(--spacing-6);
  padding: 2px;
}

.checkbox {
  position: relative;
  border: 2px solid var(--color-neutral-500);
  cursor: pointer;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  margin-right: 5px;
  transition: border-color 0.2s;
  border-radius: var(--border-radius);

  &:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid var(--color-championship-white);
    border-width: 0 2px 2px 0;

    margin: -1px -1px 0;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    border-color: var(--color-tarmac-grey-700);
  }

  &:checked {
    border-color: var(--color-tarmac-grey-700);
    background-color: var(--color-tarmac-grey-700);

    &:before {
      opacity: 1;
    }
  }

  &:disabled {
    border-color: var(--color-neutral-300) !important;
    cursor: not-allowed;
  }

  &:disabled:checked {
    background-color: var(--color-neutral-300) !important;
  }
}

.error > .checkbox {
  border-color: var(--color-error-700);

  &:hover {
    border-color: var(--color-tarmac-grey-700);
  }
}

.errorMessageContainer {
  margin-top: 28px;
  @media (--tablet) {
    margin-top: 0;
  }
}

.errorMessage {
  color: var(--color-error-700);
  font-size: 12px;
}

& [data-tenant='rac'] {
  & .checkbox {
    border-color: var(--color-rac-orange-50);

    &:checked {
      border-color: var(--color-rac-orange-50);
      background-color: var(--color-rac-orange-50);
    }
  }
}
