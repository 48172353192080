.image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  opacity: 1;
  transition: 0.2s linear;

  &[data-is-inactive='true'] {
    opacity: 0.7 !important;
  }

  @starting-style {
    opacity: 0;
  }
}

.container {
  position: relative;
  opacity: 1;
  transition: opacity 0.1s linear;
  background-color: var(--color-neutral-100);

  &[data-has-loaded='true'] {
  }

  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;

  @starting-style {
    opacity: 0;
  }
}
