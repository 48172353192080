.campaignPromotionModal {
  padding-top: var(--spacing-2);
  width: 100%;

  & > button {
    text-align: left;
    align-items: flex-start;
    padding: var(--spacing-3) var(--spacing-5);

    & > span {
      white-space: break-spaces;
    }
  }

  @media (--tablet) {
    padding-top: var(--spacing-1);
    padding-bottom: var(--spacing-5);
  }
  @media (--desktop) {
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-8);
  }
}
