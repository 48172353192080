.wrpr {
  position: relative;
  margin: 0;
  width: 100%;
  aspect-ratio: 4/3;
  /* aspect-ratio: 3 / 2.39; */
  overflow: hidden;
}

.img {
  object-fit: cover;
  object-position: center;
}

.racBadge {
  position: absolute;
  bottom: var(--spacing-2);
  left: var(--spacing-2);
  z-index: 1;
  display: flex;

  & > div {
    border-radius: var(--spacing-1);
    line-height: 20px;
  }
}

.imgsNum {
  position: absolute;
  bottom: var(--spacing-2);
  right: var(--spacing-2);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: var(--spacing-2) 6px;
  height: 32px;
  border-radius: var(--spacing-1);
  background-color: rgba(29, 29, 29, 0.8);
  color: #fff;
  font-size: 14px;
  font-weight: 500;

  & svg {
    margin-right: var(--spacing-1);
  }
}
