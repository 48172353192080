.partExchangeWrapper {
  && {
    margin: 0;
  }
}

.caption {
  margin-top: 0;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vehicleInformation {
  display: flex;
  flex-direction: column;

  @media (min-width: 420px) {
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
  }
}

.notYourCar {
  color: var(--color-mica-blue-500);
  align-items: flex-start;
  margin: 0 -6px;
  @media (--tablet) {
    align-items: flex-end;
  }
}

.lastLineContainer {
  @media (--tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.inputFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
}

.flex {
  display: flex;
  margin-bottom: var(--spacing-5);
  @media (--tablet) {
    margin-bottom: 0;
  }
  & p {
    margin: 0;
  }
}

.highlightBackground {
  background-color: var(--color-sunbeam-blue-50);
  padding-bottom: var(--spacing-3);
}

.valuationContent {
  margin: 0 20px;
}
