.accordionCheckbox {
  margin-top: var(--spacing-6);
  margin-bottom: var(--spacing-6);

  &.accordionOpen {
    border-bottom: 1px solid var(--color-neutral-50);
  }
}

.checkboxContent {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.checkboxLabel {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: var(--spacing-4);
}
