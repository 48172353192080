.wrapper {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 40px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;

  &[data-variant='alternate'] {
    justify-content: flex-start;
    width: var(--width, 100%);

    gap: 0px;

    & .dot {
      animation: slideSimple 1s infinite;
      animation-delay: calc(var(--index) * -0.05s);
      background: var(--color-tarmac-grey-200);
      width: unset;
      height: 20px;
      border-radius: 0;
      flex: 1 0 10px;
    }
  }
}

.tag {
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dot {
  width: 10px;
  height: 10px;
  background: var(--color-primary-700);
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
  animation-delay: calc(var(--index) * 0.1s);

  &:nth-child(2) {
    background: var(--color-primary-600);
  }

  &:nth-child(3) {
    background: var(--color-primary-500);
  }

  &:nth-child(4) {
    background: var(--color-primary-400);
  }

  &:nth-child(5) {
    background: var(--color-primary-300);
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideSimple {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.1;
  }
}
