.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  z-index: 0;
}

.noLinkContainer {
  & img {
    cursor: auto;
  }
}

.container {
  background: var(--color-championship-white);
  color: unset;
  text-decoration: none;

  & *:any-link {
    color: unset;
    text-decoration: none;
  }

  @media (--tablet) {
    & > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.content {
  flex-grow: 1;
  display: grid;
  grid-template-rows: [make-line] min-content [model-line] min-content [feat-line] min-content [purchase-line];
  padding: var(--spacing-3) var(--spacing-3) 0;
  border: 1px solid var(--color-tarmac-grey-100);
  border-top: 0;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.make {
  grid-row-start: make-line;
  margin: 0;
}

.model {
  grid-row-start: model-line;
  font-weight: 500;
  display: block;
  margin: var(--spacing-2) 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.labels {
  grid-row-start: feat-line;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-2);
  overflow: hidden;
  max-height: 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.label {
  border-radius: var(--spacing-1);
  padding: var(--spacing-1) var(--spacing-2);

  &:nth-child(3) {
    text-transform: capitalize;
  }
}

.price {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: var(--spacing-10);
  padding-bottom: var(--spacing-3);
  align-self: end;

  & .monthlyPrice {
    width: 100%;

    & span {
      width: 100%;
    }
  }

  & .additional {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    & span {
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  }
}

.plpStyling {
  @media (min-width: 550px) {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-3);

    & .additional {
      margin-top: var(--spacing-2);
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media (min-width: 700px) {
    display: grid;
    margin-top: var(--spacing-5);

    & .additional {
      margin-top: 0;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-2);

    & .additional {
      margin-top: var(--spacing-2);
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media (min-width: 1440px) {
    display: grid;
    margin-top: var(--spacing-10);

    & .additional {
      margin-top: 0;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.favouritesStyling {
  @media (min-width: 550px) {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-2);

    & .additional {
      margin-top: var(--spacing-1);
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media (min-width: 700px) {
    display: grid;
    margin-top: var(--spacing-5);

    & .additional {
      margin-top: 0;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.image {
  border: 1px solid var(--color-tarmac-grey-100);
  border-bottom: none;
  position: relative;

  & img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 1 !important;
  }

  & .badge {
    position: absolute;
    left: var(--spacing-2);
    top: var(--spacing-2);

    & > div {
      background-color: var(--color-warning-500);
      border-radius: var(--spacing-1);
      color: var(--color-neutral-900);
      line-height: 20px;
    }
  }
  & .ecomBadge {
    position: absolute;
    left: var(--spacing-2);
    top: var(--spacing-2);

    & > div {
      border-radius: var(--spacing-1);
      color: var(--color-neutral-900);
      line-height: 20px;
    }
  }
}

.noImageBorder {
  border: 0;
}

.leasingTileFooter {
  background-color: white;
  padding: var(--spacing-2) var(--spacing-3);

  & > span {
    font-size: 11px;
    line-height: 14px;
  }
}

.plpTileFooter {
  padding: var(--spacing-2) var(--spacing-2);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: var(--neutral-600);
}

.ecomTileFooter {
  background-color: white;
  height: 48px;

  &[data-finance='false'] {
    height: 32px;
    @media (--desktop) {
      height: 48px;
    }
  }

  &:focus-within {
    background-color: white;
  }

  &:active {
    background-color: var(--white);
  }
}

.delivery {
  grid-area: delivery;
  justify-self: end;

  & > span {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    text-transform: capitalize;
    &[data-variant='has-distance'] {
      text-transform: inherit;
    }
  }
}
