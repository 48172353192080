.richText {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &.noPadding {
    padding: 0;
  }

  & h1,
  & h2 {
    font-size: 32px;
    letter-spacing: -0.5px;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family-system);
  }

  & h3,
  & h4 {
    margin-bottom: var(--spacing-2);

    &:not(:first-child) {
      margin-top: var(--spacing-5);
    }
  }
  /* body 3 */
  & p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }
  a {
    color: var(--color-primary-500);
    text-decoration: none;
  }
  & p:not(:last-of-type) {
    padding-bottom: var(--spacing-3);
  }

  & li {
    font-size: 16px;
    line-height: 26px;
    margin: var(--spacing-1) 0;
  }

  @media (--tablet) {
    padding: 0;

    & h1 {
      font-size: 48px;
    }

    & h1,
    & h2 {
      margin-bottom: var(--spacing-5);
    }
  }
}

.richTextCtaContainer {
  display: flex;
  @media (--tablet) {
    display: none;
    margin: 0;
  }
  & .overflownText {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  & .viewMore {
    align-items: end !important;
    cursor: pointer;
    bottom: var(--spacing-1) !important;
  }
}
