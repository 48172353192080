/*
* Base
*/
.logo {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  color: inherit;
}

/*
* Colors
*/

.dark_bg {
  fill: var(--color-championship-white);
}
.light_bg {
  fill: var(--color-mica-blue);
}
.mint_bg {
  fill: var(--color-mica-blue);
}

.undermark_dark_bg {
  fill: var(--color-heycar-mint-500);
}
.undermark_light_bg {
  fill: var(--color-heycar-mint-500);
}
.undermark_mint_bg {
  fill: var(--color-championship-white);
}
