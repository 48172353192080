.dealers {
  border: 1px solid var(--color-tarmac-grey-100);
  border-radius: var(--spacing-1);
  display: flex;
  height: 400px;
  overlow: hidden;

  &.error {
    border-color: var(--color-red-700);
  }
}

.errorMessage {
  color: var(--color-red-700);
  display: block;
  font-size: 14px;
  margin-left: var(--spacing-4);
}

.dealerList {
  overflow-y: scroll;
  position: relative;
  width: 55%;

  @media (--mobile) {
    width: 100%;
  }

  & span {
    left: -8px;
    top: var(--spacing-2);
    margin-left: var(--spacing-2);
    margin-top: -6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color-tarmac-grey-500);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    flex-shrink: 0;

    &:hover {
      border-color: var(--color-tarmac-grey-900);
      &::after {
        background-color: var(--color-neutral-700);
      }
    }

    /* for inner filled circle */
    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background: var(--color-neutral-700);
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  .error {
    border-color: var(--color-red-700);
  }

  & input {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 2px solid var(--color-tarmac-grey-500);

    &:checked + span {
      border: 2px solid var(--color-neutral-700);
    }

    &:checked + span:after {
      opacity: 1;
    }

    &:disabled + span {
      color: var(--color-tarmac-grey-300);
      &::before,
      &::after {
        border-color: var(--color-tarmac-grey-500);
      }
    }
  }

  & label {
    font-size: 14px;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    line-height: 22px;
    &:disabled + label {
      cursor: default;
    }
  }

  &:disabled + label {
    cursor: default;
    pointer-events: none;
  }

  & small {
    display: block;
    margin: 4px 0;
    color: var(--color-red-700);
  }
}
.icon {
  padding-left: var(--spacing-4);

  @media (--mobile) {
    margin-top: var(--spacing-1);
    display: block;
    width: 100%;
  }

  @media (--mobile) {
    width: 100%;
  }
}

.dealer {
  align-items: flex-start;
  display: flex;
  padding: var(--spacing-4);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-tarmac-grey-100);
  }

  & .dealerInfo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.dealerNumber {
  display: none;

  @media (--desktop) {
    display: inline;
  }
}

.dealerName {
  line-height: 22px;
}

.dealerAddress {
  color: var(--color-tarmac-grey-500);
  font-size: 14px;
  line-height: 22px;
}

.dealerMap {
  flex: 1;

  & > div {
    flex: 1;
  }

  @media (--mobile) {
    display: none;
  }
}
