.container {
  margin: var(--spacing-5) 0;
  padding: var(--spacing-5);
  background-color: var(--color-sunbeam-blue-50);
}

.title {
  margin: 0 0 var(--spacing-5) 0;
}

.content {
  display: flex;
  gap: var(--spacing-3);
  align-items: center;
  user-select: none;
  @media (--tablet) {
    display: flex;
  }
}

.subtitle {
  line-height: 23px;
}
