undefined {
  @media (--$(breakpoint)) {
    .width-$(breakpoint)-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    .width-$(breakpoint)-available {
      flex-basis: auto;
      flex-grow: 1;
      min-height: auto;
      height: auto;
    }
      .width-$(breakpoint)-1 {
        flex: 0 0 calc(var(--grid-col-width) * 1);
        max-width: calc(var(--grid-col-width) * 1);
      }
      .width-$(breakpoint)-2 {
        flex: 0 0 calc(var(--grid-col-width) * 2);
        max-width: calc(var(--grid-col-width) * 2);
      }
      .width-$(breakpoint)-3 {
        flex: 0 0 calc(var(--grid-col-width) * 3);
        max-width: calc(var(--grid-col-width) * 3);
      }
      .width-$(breakpoint)-4 {
        flex: 0 0 calc(var(--grid-col-width) * 4);
        max-width: calc(var(--grid-col-width) * 4);
      }
      .width-$(breakpoint)-5 {
        flex: 0 0 calc(var(--grid-col-width) * 5);
        max-width: calc(var(--grid-col-width) * 5);
      }
      .width-$(breakpoint)-6 {
        flex: 0 0 calc(var(--grid-col-width) * 6);
        max-width: calc(var(--grid-col-width) * 6);
      }
      .width-$(breakpoint)-7 {
        flex: 0 0 calc(var(--grid-col-width) * 7);
        max-width: calc(var(--grid-col-width) * 7);
      }
      .width-$(breakpoint)-8 {
        flex: 0 0 calc(var(--grid-col-width) * 8);
        max-width: calc(var(--grid-col-width) * 8);
      }
      .width-$(breakpoint)-9 {
        flex: 0 0 calc(var(--grid-col-width) * 9);
        max-width: calc(var(--grid-col-width) * 9);
      }
      .width-$(breakpoint)-10 {
        flex: 0 0 calc(var(--grid-col-width) * 10);
        max-width: calc(var(--grid-col-width) * 10);
      }
      .width-$(breakpoint)-11 {
        flex: 0 0 calc(var(--grid-col-width) * 11);
        max-width: calc(var(--grid-col-width) * 11);
      }
      .width-$(breakpoint)-12 {
        flex: 0 0 calc(var(--grid-col-width) * 12);
        max-width: calc(var(--grid-col-width) * 12);
      }
      .offset-$(breakpoint)-1 {
        margin-left: calc(var(--grid-col-width) * 1);
      }
      .offset-$(breakpoint)-2 {
        margin-left: calc(var(--grid-col-width) * 2);
      }
      .offset-$(breakpoint)-3 {
        margin-left: calc(var(--grid-col-width) * 3);
      }
      .offset-$(breakpoint)-4 {
        margin-left: calc(var(--grid-col-width) * 4);
      }
      .offset-$(breakpoint)-5 {
        margin-left: calc(var(--grid-col-width) * 5);
      }
      .offset-$(breakpoint)-6 {
        margin-left: calc(var(--grid-col-width) * 6);
      }
      .offset-$(breakpoint)-7 {
        margin-left: calc(var(--grid-col-width) * 7);
      }
      .offset-$(breakpoint)-8 {
        margin-left: calc(var(--grid-col-width) * 8);
      }
      .offset-$(breakpoint)-9 {
        margin-left: calc(var(--grid-col-width) * 9);
      }
      .offset-$(breakpoint)-10 {
        margin-left: calc(var(--grid-col-width) * 10);
      }
      .offset-$(breakpoint)-11 {
        margin-left: calc(var(--grid-col-width) * 11);
      }

    .order-$(breakpoint)-first {
      order: -1;
    }

    .order-$(breakpoint)-last {
      order: 13;
    }
      .order-$(breakpoint)-1 {
        order: 1;
      }
      .order-$(breakpoint)-2 {
        order: 2;
      }
      .order-$(breakpoint)-3 {
        order: 3;
      }
      .order-$(breakpoint)-4 {
        order: 4;
      }
      .order-$(breakpoint)-5 {
        order: 5;
      }
      .order-$(breakpoint)-6 {
        order: 6;
      }
      .order-$(breakpoint)-7 {
        order: 7;
      }
      .order-$(breakpoint)-8 {
        order: 8;
      }
      .order-$(breakpoint)-9 {
        order: 9;
      }
      .order-$(breakpoint)-10 {
        order: 10;
      }
      .order-$(breakpoint)-11 {
        order: 11;
      }
      .order-$(breakpoint)-12 {
        order: 12;
      }
  }
}