.guaranteeWrapper {
  && {
    @media (--tablet) {
      display: none;
    }
    margin: var(--spacing-5) 0 -8px 0;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }
}

.modalContainer {
  @media (--tablet-l) {
    width: 800px;
  }
}
