.wrpr {
  position: relative;

  & button {
    &:hover {
      background: transparent;
    }
  }
}

.dialog {
  position: fixed;
  background: var(--color-neutral-700);
  box-spacing: border-box;
  padding: var(--spacing-1) var(--spacing-4);
  max-width: 18em;
  width: max-content;
  color: #fff;
  z-index: 9999;
  transform: translate(-50%, 0);

  & > p {
    margin: 1px 0;
  }

  & a {
    color: #fff;
  }

  &.darkMode {
    background: #fff;
    color: var(--color-neutral-800);

    & a {
      color: var(--color-neutral-800);
    }
  }

  &:global(.top) {
    transform: translate(-50%, -100%);
  }
  &:global(.top-right) {
    transform: translate(0, -100%);
  }
  &:global(.top-left) {
    transform: translate(-100%, -100%);
  }

  &:global(.right) {
    transform: translate(0, -50%);
  }
  &:global(.right-top) {
    transform: translate(0, -100%);
  }
  &:global(.right-bottom) {
    transform: translate(0, 0);
  }

  &:global(.bottom-right) {
    transform: translate(0, 0);
  }
  &:global(.bottom-left) {
    transform: translate(-100%, 0);
  }

  &:global(.left) {
    transform: translate(-100%, -50%);
  }
  &:global(.left-top) {
    transform: translate(-100%, -100%);
  }
  &:global(.left-bottom) {
    transform: translate(-100%, 0);
  }
}

.closeIcon,
.infoIcon {
  width: 24px;
  height: 24px;
  && {
    min-height: initial;
    background: transparent;
    color: var(--color-tarmac-grey-700);
    padding: 0;
    cursor: pointer;
  }
}
.closeIcon {
  position: absolute;
  right: 4px;
  top: 7px;
}
