.skltn {
  & > svg {
    width: 100%;
  }
}

.acrdn {
  && {
    padding: 0 var(--spacing-4);
    background-color: var(--color-primary-50);
    border-bottom: 1px solid var(--color-neutral-100);
  }

  & > [data-accordion-component='AccordionItemButton'] {
    margin-bottom: 0;
  }

  & > [data-accordion-component='AccordionItemPanel'] > label {
    margin-bottom: var(--spacing-2);
    padding: var(--spacing-2) 0 var(--spacing-3);
  }
}
