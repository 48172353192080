.leadsModalfooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & p {
    width: auto;
    @media (--tablet) {
      width: 70%;
    }

    & a {
      color: var(--color-neutral-700);
    }
  }
  & button {
    height: 0;
  }
  @media (--tablet) {
    flex-direction: row;
  }
}
