.financeQuoteWrapper {
  && {
    margin: 0 var(--spacing-5);
  }
}

.checkboxQuoteContainer {
  @media (--tablet) {
    display: flex;
    align-items: center;
    gap: var(--spacing-6);
  }
}

.checkboxQuoteTcsContainer {
  @media (--tablet) {
    display: flex;
    flex-direction: column;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkboxTcsContainer {
  margin-top: var(--spacing-3);
  display: flex;
  align-items: center;
  gap: 10px;
}

.disabled {
  color: var(--color-neutral-300);
}

.error {
  color: var(--color-error-700);
}

.financeInformation {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-3);
}

.financeInformationContainer {
  display: flex;
  flex-direction: column;
}

.genericFinanceDetails {
  @media (--tablet) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
  }
}

.pcpFinanceDetails {
  @media (--tablet) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
  }
}

.quoteText {
  margin: 0 0 var(--spacing-2) 0;
  color: var(--color-mica-blue-500);
  @media (--tablet) {
    margin: 16px 0;
  }
}

.bodyText {
  margin: var(--spacing-2);
  white-space: nowrap;
}

.errorMessage {
  color: var(--color-error-700);
  font-size: 12px;
}
