.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-4);

  .error {
    border-color: var(--color-red-700);
  }

  & label {
    font-size: 14px;
    font-weight: 400;
    margin-right: 7px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: baseline;
    line-height: 1.4;

    &:disabled + label {
      cursor: default;
    }

    &.alignCenter {
      align-items: center;
    }
  }

  & .label {
    display: flex;
    flex-wrap: wrap;
  }

  &:disabled + label {
    cursor: default;
    pointer-events: none;
  }

  & small {
    display: block;
    margin: 4px 0;
    color: var(--color-red-700);
  }
}
