.bookAppointmentWrapper {
  && {
    margin: 0;
  }
}

.inputFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
}

.dropDown {
  & ul {
    position: relative;
    top: 0;
  }
}

.dealerName {
  display: flex;
  padding-top: var(--spacing-3);
  margin-bottom: 20px;
  gap: var(--spacing-2);
  @media (--tablet) {
    margin-bottom: 0;
  }
  & p {
    margin: 0;
  }
}
