.oneClickCheckbox {
  display: flex;
  gap: var(--spacing-3);
  align-items: center;
  user-select: none;

  & .termHref {
    color: var(--color-neutral-800);
  }

  @media (--tablet) {
    margin-bottom: var(--spacing-3);
  }
}

.highlight {
  font-weight: 700;
}

.tooltip {
  display: inline-block;
  width: 28px;
  margin-left: var(--spacing-2);
}
