.component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  box-sizing: border-box;
}

.top {
  align-items: flex-start;
}

.middle {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.around {
  justify-content: space-around;
}

.between {
  justify-content: space-between;
}
