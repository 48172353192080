.wrapper {
  padding: var(--spacing-10) 0 var(--spacing-20);

  @media (--mobile) {
    padding: var(--spacing-5) 0;
  }
}

.card {
  display: none;

  &[data-is-mobile='true'] {
    display: none;

    @media (--mobile) {
      display: block;
      margin-bottom: var(--spacing-4);
    }
  }
}
