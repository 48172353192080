.wrapper {
  max-width: 1312px;
  padding-bottom: var(--spacing-5);
  margin: var(--spacing-4) 0;
  background-color: var(--color-heycar-mint-100);

  & > header {
    margin-bottom: var(--spacing-5);
    padding: var(--spacing-5) var(--spacing-5);
    background-color: var(--color-heycar-mint-400);

    & > h1 {
      margin: 0;
    }
  }

  & > p {
    margin-left: var(--spacing-5);
    margin-right: var(--spacing-5);
  }

  & a {
    color: var(--color-primary-500);
    text-decoration: underline;
  }

  .modalBody {
    padding: var(--spacing-5);
  }
}
