.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);

  @media (--tablet) {
    padding-right: var(--spacing-6);
    padding-left: var(--spacing-6);
  }

  @media (--tablet-l) {
    padding-right: var(--spacing-8);
    padding-left: var(--spacing-8);
  }

  @media (--desktop) {
    padding-right: var(--spacing-8);
    padding-left: var(--spacing-8);
  }

  @media (--desktop-m) {
    padding-right: var(--spacing-8);
    padding-left: var(--spacing-8);
  }

  @media (--desktop-l) {
    max-width: 1440px;
    padding-right: var(--spacing-16);
    padding-left: var(--spacing-16);
  }
}
