.container {
  --aspect-ratio: 4/3;
  width: 100%;
  aspect-ratio: var(--aspect-ratio);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  position: relative;
  contain: layout;

  user-select: none;
  user-drag: none;
  transition: 0.4s;
  pointer-events: none;

  [class*='patchWorkGalleryContainer'] & {
    margin-top: 60px;
    margin-bottom: 40px;

    & [data-icon='Arrow360'] {
      bottom: min(55px, 10vw);

      @media (--tablet-l) {
        bottom: 100px;
      }
    }

    & .heading {
      bottom: 25px;

      @media (--tablet-l) {
        bottom: 70px;
      }
    }
  }

  &[data-is-initialised='true'] {
    pointer-events: all;
  }

  &[data-was-used='true'],
  &[data-is-initialised='false'] {
    & [data-icon='Arrow360'] {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  & [data-icon='Arrow360'] {
    position: absolute;
    bottom: 65px;
    max-width: min(calc(100% - 100px), 600px);
    pointer-events: none;

    color: white;
    z-index: 10;
    transition: 1s;
  }
}

.container:active {
  cursor: grabbing;
}

.images {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  user-drag: none;
  position: absolute;
  opacity: 0;
  z-index: 0;
  pointer-events: none;

  &[data-is-active='true'] {
    opacity: 1;
    z-index: 1;
  }
}

.heading {
  position: absolute;
  bottom: 40px;
  pointer-events: none;

  color: white;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  font-size: clamp(14px, 2vw, 16px);

  transition: 1s;
  transition-delay: 0.3s;

  [data-is-initialised='false'] & {
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(10px);
  }

  [data-was-used='true'] & {
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(10px);
  }

  &[data-alt-view='false'] {
    display: block;
    [class*='patchWorkGalleryContainer'] & {
      display: none;
    }
  }

  &[data-alt-view='true'] {
    display: none;
    [class*='patchWorkGalleryContainer'] & {
      display: block;
    }
  }
}

.icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;

  right: 16px;
  gap: 12px;
  transition: 1s;
  cursor: pointer;

  &:active {
    scale: 0.9;
  }

  [data-is-loading='true'] & {
    opacity: 0;
    transform: translateX(10px);
  }
}

.icon {
  background: var(--color-neutral-800);
  opacity: 0.8;
  color: white;
  height: 52px;
  width: 52px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.initialising {
  z-index: 20;
  background: rgba(255, 255, 255, 0);
  position: absolute;
  inset: 0;
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;

  opacity: 1;
  pointer-events: none;

  [data-is-initialised='true'] & {
    backdrop-filter: blur(0);
    opacity: 0;
    pointer-events: none;
  }

  & svg {
    height: 60px;
    width: 60px;

    path {
      animation: animate 2s infinite alternate;
    }
  }
}

.fav {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 101;

  display: flex;
  position: absolute;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--color-championship-white);
  cursor: pointer;

  &[data-is-patchwork='true'] {
    display: none;
  }

  [class*='patchWorkGalleryContainer'] & {
    background: none;
    color: white;
    right: 0;
    top: 16px;

    &[data-is-patchwork='true'] {
      display: flex;
    }

    &[data-is-patchwork='false'] {
      display: none;
    }

    @media (--tablet-l) {
      right: 32px;
    }

    @media (--desktop-l) {
      right: 60px;
    }
  }
}

/*keyframe animate between colors */

@keyframes animate {
  0% {
    fill: var(--color-tarmac-grey-50);
  }
  50% {
    fill: var(--color-primary-400);
  }
  100% {
    fill: var(--color-secondary-600);
  }
}
