.marketingOptIn {
  & label {
    margin: var(--spacing-5) 0;
    padding: var(--spacing-5) var(--spacing-4);
    display: flex;
    gap: var(--spacing-3);
    align-items: center;
    user-select: none;
  }
}

& [data-tenant='rac'] {
  .marketingOptIn {
    & label {
      font-weight: 200;
      background-color: var(--color-primary-100);

      & span {
        font-weight: 200;
      }
    }
  }
}
