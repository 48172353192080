.expandable {
  height: 48px;
  z-index: 6;
  position: absolute;
  bottom: 50px;
  height: max-content;
  width: 100%;
  background-color: white;
  padding: 0 var(--spacing-4);
  border-right: 1px solid var(--color-tarmac-grey-100);
  border-left: 1px solid var(--color-tarmac-grey-100);

  & .financePrice {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin: var(--spacing-3) 0;
    color: var(--neutral-700);
    color: var(--color-tarmac-grey-700);
  }

  & .financeCopy {
    width: 100%;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tarmac-grey-700);
    padding-bottom: var(--spacing-3);

    & > * {
      font-size: 12px !important;
    }
  }
}

.readMe {
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  line-height: var(--spacing-4);
  position: absolute;
  right: var(--spacing-1);
  bottom: var(--spacing-2);
  color: var(--color-tarmac-grey-700);
  text-underline-offset: 1px;
  text-decoration-thickness: 1px;
  text-decoration: underline var(--color-tarmac-grey-700);
}

.finance {
  position: absolute;
  margin: 0;
  bottom: var(--spacing-2);
  left: var(--spacing-3);
  right: 70px;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: var(--neutral-600);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:active {
    background-color: var(--color-championship-white);
  }

  & > * {
    font-size: 12px !important;
  }
}

.noFinance {
  font-size: 10px;
  font-weight: 400;
  line-height: var(--spacing-4);
  padding: 0 var(--spacing-3);
  margin-top: var(--spacing-2);
}
