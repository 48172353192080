.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-5);
  text-align: center;
  & svg {
    color: var(--color-championship-white);
  }
}
