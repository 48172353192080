@keyframes scroll {
  0%,
  83.33% {
    transform: translateY(0);
  }
  91.66%,
  100% {
    transform: translateY(100%);
  }
}

.scrollWrapper {
  display: flex;
  margin-top: var(--spacing-3);
  overflow: hidden;
  height: 40px;

  @media (--tablet-l) {
    max-width: 300px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & .scrollItem {
    display: none;
  }

  & .visible {
    display: flex;
    animation: scroll 4s forwards;
    position: sticky;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  & .label {
    border-radius: var(--spacing-1);
    padding: var(--spacing-1) var(--spacing-2);
    background-color: #d9f0ff;

    &:nth-child(3) {
      text-transform: capitalize;
    }

    & .uspIcon {
      position: relative;
      aspect-ratio: 1/1;
      height: 20px;
      margin-right: var(--spacing-2);
    }
  }
}

.link {
  margin-top: var(--spacing-3);
  color: var(--color-neutral-700);
}
