.badges {
  position: absolute;
  top: var(--spacing-2);
  left: var(--spacing-2);
  z-index: 1;
  display: flex;
  gap: var(--spacing-2);

  & > div {
    border-radius: var(--spacing-1);
    line-height: 20px;
  }
}
