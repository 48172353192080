.info {
  cursor: pointer;
  min-height: inherit;
  margin: 0;
  padding: 0;
  line-height: var(--spacing-3);
  color: var(--color-neutral-700);
}

.modalContainer {
  && {
    padding: 0;
  }
  @media (--tablet-l) {
    height: 560px;
    width: 800px;
  }
}

.modalBodyClassName {
  padding: var(--spacing-8) var(--spacing-5);
  font-size: 12px;
  & > p {
    margin: 0;
  }

  @media (--tablet) {
    && {
      padding: var(--spacing-8) var(--spacing-6);
    }
  }
  @media (--tablet-l) {
    && {
      padding: var(--spacing-8) var(--spacing-10);
    }
  }
}
