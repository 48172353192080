.recaptcha {
  margin-top: var(--spacing-5);
  text-decoration: none;
  display: block;

  & a {
    color: var(--color-neutral-700);
    font-weight: 700;
  }
}
