.reviewsRating {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ratingDivider {
  border-left: 1px solid var(--neutral-300-disabled, #b5b5b5);
  padding-left: var(--spacing-2);
}

.reviewsBody {
  display: flex;
  padding: var(--spacing-4);
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  & h3 {
    margin: 0 !important;
  }

  .title {
    text-transform: capitalize;
    font-size: 16px;
  }

  .subTitle {
    color: var(--color-neutral-500);
  }
}

.reviewSlug {
  text-decoration: underline !important;
}
