.contentWrapper {
  padding: var(--spacing-5) 0;

  & h6 {
    margin: 0;
  }

  & .guaranteesList {
    padding-left: var(--spacing-6);
    && {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}
